<!-- =========================================================================================
    File Name: IdealReportTable.vue
    Description: Таблица смен
========================================================================================== -->

<template>
  <div class="mt-4">
    <div class="flex flex-wrap items-center mb-3">
      <div class="w-full sm:w-1/2 h-full">
        <vs-input
          class="w-full"
          placeholder="Поиск по номеру смены"
          id="number-search"
          ref="numberSearch"
          v-model="shift_number"
          @input="debouncedGetData()"
          @keyup.esc="
            shift_number = '';
            debouncedGetData();"/>

      </div>
      <div class="w-full sm:w-1/2 text-right">
        Время обновления: {{ $helpers.formatDateTime(header.date_created * 1000) }}
      </div>
    </div>

    <vs-table
      :data="data"
      sst
      hoverFlat
      stripe>
      <template slot="thead">
        <vs-th>Источник дохода</vs-th>
        <vs-th>Сумма</vs-th>
        <vs-th>Посетителей</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td v-if="!tr.items" class="td-check" />
          <vs-td :data="tr">{{ tr.category_name }}</vs-td>
          <vs-td :data="tr">
            {{ tr.sum_opened != null ? 'Потенциально: ' + tr.sum_opened + ' |' : ''}} 
            {{ tr.sum_closed != null ? 'Получено: ' + tr.sum_closed + ' |' : ''}} 
            {{ tr.sum_total != null ? 'Итого: ' + tr.sum_total : ''}} 
          </vs-td>
          <vs-td :data="tr">
            <span
              v-if="tr.customers_opened != null"
              class="customers-filter-link"
              @click.stop="showCustomers(tr, 'active')">
                В пространстве: {{ tr.customers_opened }} |
              </span>
            <span
              v-if="tr.customers_closed != null"
              class="customers-filter-link"
              @click.stop="showCustomers(tr, 'inactive')">
                Покинули: {{ tr.customers_closed }} |
            </span>
            <span
              v-if="tr.customers_total != null"
              class="customers-filter-link"
              @click.stop="showCustomers(tr, 'all')">
                Всего: {{ tr.customers_total }}
            </span>
          </vs-td>
          <template v-if="tr.items" class="expand-shift" slot="expand">
            <div class="con-expand-shifts w-full">
              <div class="border border-dashed border-grey border-l-0 border-r-0 border-t-0 pl-8">
                <vs-table :data="tr.items" hoverFlat stripe>
                  <template slot="thead">
                    <vs-th>Источник</vs-th>
                    <vs-th>Сумма</vs-th>
                    <vs-th>Посетителей</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" ref="trs">
                      <vs-td :data="tr">{{ tr.category_name }}</vs-td>
                      <vs-td :data="tr">
                        {{ tr.sum_opened != null ? 'Потенциально: ' + tr.sum_opened + ' |' : ''}} 
                        {{ tr.sum_closed != null ? 'Получено: ' + tr.sum_closed + ' |' : ''}} 
                        {{ tr.sum_total != null ? 'Итого: ' + tr.sum_total : ''}} 
                      </vs-td>
                      <vs-td :data="tr">
                        <span
                          v-if="tr.customers_opened != null"
                          class="customers-filter-link"
                          @click.stop="showCustomers(tr, 'active')">
                            В пространстве: {{ tr.customers_opened }} |
                          </span>
                        <span
                          v-if="tr.customers_closed != null"
                          class="customers-filter-link"
                          @click.stop="showCustomers(tr, 'inactive')">
                            Покинули: {{ tr.customers_closed }} |
                        </span>
                        <span
                          v-if="tr.customers_total != null"
                          class="customers-filter-link"
                          @click.stop="showCustomers(tr, 'all')">
                            Всего: {{ tr.customers_total }}
                        </span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import BaseTable from "./BaseTable";
import Inputmask from "inputmask";

export default {
  mixins: [BaseTable],
  name: "ideal-report-table",
  data() {
    return {
      shift_number: "",
      data: [],
      header: {},
    };
  },
  methods: {
    async getData(noCache) {
      let shift_number = this.shift_number ? this.shift_number : 0;
      noCache = noCache ? 1 : 0;

      await this.$http({
        method: "GET",
        url: `/udata/emarket/get_ideal_report/${shift_number}/${noCache}.json`,
        withCredentials: true
      })
        .then(response => {
          if (!response.data.error) {
            this.header = response.data.header;
            this.data = Object.values(response.data.items);
          } else {
            this.$vs.notify({
              title: "Ошибка",
              text: response.data.error,
              color: "warning",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Ошибка",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },
    showCustomers(tr, with_active_visits) {
      let filter = {
        ideal_report_filter: {
          category_name: tr.category_name,
          shift_id: tr.shift_id,
          category_id: tr.category_id,
          where_field: tr.where_field,
          func: tr.func,
          args: tr.args,
          with_active_visits: with_active_visits
        }
      };
      this.$store.commit("SET_IDEAL_REPORT_FILTER", filter);
      this.$router.push("customer-search");
    }
  },
  created() {
    let shift_number = this.$route.params.shift_number ? this.$route.params.shift_number : '';
    this.shift_number = shift_number;
    this.getData();
  },
  mounted() {
    var search_input = document.getElementById("number-search");
    Inputmask("9{*}").mask(search_input);
  }
};
</script>

<style lang="scss">
@import "src/assets/scss/vuesax/_variables.scss";

.customers-filter-link:hover {
  color: $primary;
}
</style>
