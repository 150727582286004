<!-- =========================================================================================
    File Name: IdealReport.vue
    Description: Идеальный отчет
========================================================================================== -->


<template>
    <vx-card title="Идеальный отчет">
        <div class="flex">
            <api-button icon-pack="feather" icon="icon-refresh-cw" type="border" class="mr-2" :click="refreshData" />
            <vs-button class="mr-2" @click="downloadCSV()">Скачать CSV</vs-button>
        </div>
        <ideal-report-table ref="table" />
    </vx-card>
</template>


<script>
import IdealReportTable from '../components/IdealReportTable.vue'
var _omit = require('lodash/omit')
const { Parser } = require('json2csv')

export default {
    components: {
        IdealReportTable
    },
    data() {
        return {
            current_shift_number: null
        }
    },
    methods: {
        downloadCSV() {
            // Flatten object
            let table = this.$refs.table; 
            let data = table.data;
            let flatdata = [];
            Object.values(data).forEach(row => {
                flatdata.push(_omit(row, 'items'));
                if (row.items) {
                    Object.values(row.items).forEach(subrow => {
                        flatdata.push(Object.assign({}, 
                            subrow, 
                            {sub_category_name: subrow.category_name,
                            category_name: row.category_name}
                        ));
                    })
                }
            });
            
            try {
                const fields = [{
                    label: 'Источник дохода',
                    value: 'category_name'
                },{
                    label: 'Источник дохода - сегмент',
                    value: 'sub_category_name'
                },{
                    label: 'Сумма - потенциально',
                    value: 'sum_opened'
                },{
                    label: 'Сумма - получено',
                    value: 'sum_closed'
                },{
                    label: 'Сумма - итого',
                    value: 'sum_total'
                },{
                    label: 'Посетители - в пространстве',
                    value: 'customers_opened'
                },{
                    label: 'Посетители - покинули',
                    value: 'customers_closed'
                },{
                    label: 'Посетители - всего',
                    value: 'customers_total'
                }];
                const json2csvParser = new Parser({fields});
                const csv = json2csvParser.parse(flatdata);
                let shift_number = table.shift_number ? table.shift_number : this.current_shift_number;
                let filename = 'отчет_по_смене_' + shift_number + '__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv';
                this.$helpers.downloadCSV(csv, filename);
            } catch (err) {
                this.$vs.notify({
                    color:'danger',
                    title:'Ошибка',
                    text:err
                });
            }
        },
        async refreshData() {
            await this.$refs.table.getData(true);
        }
    },
    mounted() {
        this.$http({
            method: 'get',
            url: '/udata/emarket/get_current_shift_number/.json',
            withCredentials: true
        }).then((response) => {
            if (!response.data.error) {
                this.current_shift_number = response.data.result;
            } else {
                this.$vs.notify({
                    color:'warning',
                    title:'Ошибка',
                    text:response.data.error
                })
            }
        }).catch(err => {
            this.$vs.notify({
                color:'danger',
                title:'Ошибка',
                text:err
            });
        });
    }
}
</script>
